const {baseAPI} = require('../config/config.json');
const axios = require('axios');
const storageService = require('./storage_service');
const HEADERS = { 'Content-Type': 'application/json' };

const login = (username, password) => {
    let body = {
        identifier: username,
        password
    }
    return axios.post(`${baseAPI}/auth/local`, body, HEADERS)
}

const getSouscriptionConfig = (uid) => {
    return axios.get(`${baseAPI}/agences?user_id=${uid}`, HEADERS)
}


const getSouscriptions = (uid) => {
    return axios.get(`${baseAPI}/voyage_submissions?direct_user_id=${uid}&_sort=date_de_souscription:DESC&_limit=-1`, HEADERS)
}

const submitAndAttestation = (_result) => {
    const user = storageService.getUser();
    let body = {
        result: _result,
        uid: user.id
    }
    return axios.post(`${baseAPI}/agences/attestation`, body, HEADERS)
}

const forgotPassword = (_email) => {
    let body = {
        email: _email
    }
    return axios.post(`${baseAPI}/agences/forgot`, body, HEADERS)
}

const changePassword = (cP, p ) => {
    const user = storageService.getUser();
    let body = {
        uid: user.id,
        currentPassword: cP,
        password: p
    }
    return axios.post(`${baseAPI}/agences/change_password`, body, HEADERS)
}

const saveProfile = (uId, params ) => {
    return axios.put(`${baseAPI}/users/${uId}`, params, HEADERS)
}

const logout = () => {
    storageService.clearAuth();
}

module.exports = {
    login,
    logout,
    getSouscriptionConfig,
    submitAndAttestation,
    getSouscriptions,
    forgotPassword,
    changePassword,
    saveProfile
}